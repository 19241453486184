import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import YourStyleImg from "../../assets/images/themes/your-style.webp"
import YourStyleMImg from "../../assets/images/themes/your-style-m.webp"
import FavoriteThemesMImg from "../../assets/images/themes/favorite-themes-m.webp"
import WorkPersonalMImg from "../../assets/images/themes/work-personal-m.webp"

const Themes = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        title="Personalize Your Palette | BlueMail App"
        description="Immerse yourself in an email experience that's tailored to your taste with BlueMail's extensive theme options."
        image="/img/OG/og_image-themes.png"
        url="features-functions/themes"
      />
      <Navbar />
      <section className="features-functions">
        <div className="container-fluid favorite-themes w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h1 className="page-title">
                  <span>Choose Your Favorite</span> Themes
                </h1>
                <p className="lead">
                  Discover the power of personal preference with BlueMail's themes. Whether you're drawn to the tranquility of pastels or the energy of bright hues, there's a palette to echo your personality. Make your email workspace a reflection of what you love.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid theme-preview w-991">
          <div className="row">
            <div className="theme-image col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={FavoriteThemesMImg} alt="Customizable themes preview" />
            </div>
          </div>
        </div>
        <div className="container-fluid work-personal w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2 className="section-title">
                  <span>Professional</span> or <span>Personal:</span> Tailor Your Tone
                </h2>
                <p>
                  BlueMail adapts to your world. Opt for a theme that complements your professional environment with subtle elegance or personalizes your screen with a burst of color that captures your private vibrancy. It's your email, your rules.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid theme-preview w-991">
          <div className="row">
            <div className="theme-image col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={WorkPersonalMImg} alt="Themes suitable for work and personal emails" />
            </div>
          </div>
        </div>
        <div className="container-fluid your-style">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2 className="section-title">
                  <span>Match Your</span> Style: Bold or Subdued
                </h2>
                <p>
                  Embrace a style that's truly yours. BlueMail's themes let you express yourself whether you're in the mood for something that stands out with vivid colors or prefer a more minimalist approach with muted tones. Your email's appearance is now in your hands.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="theme-image col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img className="desktop-preview d-992" src={YourStyleImg} alt="Themes that match your style" />
              <img
                className="mobile-preview w-991 mt-3 mb-5"
                src={YourStyleMImg}
                alt="Mobile-friendly themes that reflect your style"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}



export default Themes

export const query = graphql`
  query ThemesPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
